<template>
  <div>
    <!--begin::BusinessAccount-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 v-if="!edit_form" class="card-label">Business Accounts Add</h3>
          <h3 v-else class="card-label">Business Account Updates</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToBusinessAccountList()"
                >
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form
          class="form"
          novalidate="novalidate"
          id="kt_business_account_form"
        >
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="business_account.name"
                    :counter="25"
                    :rules="nameRules"
                    label="Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                  v-model="business_account.selected_users"
                  :items="company_user_list"
                  label="Account Holder"
                  :rules="[v => !!v || 'Account Holder is required']"
                  multiple
                  dense
                  outlined
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            business_account.selected_users.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Select All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:append-item>
                    <v-divider class="mb-2"></v-divider>
                  </template>
                </v-select>
                </v-col>
              </v-row>

              <v-row no-gutters>
                  <v-select
                    class="pr-2"
                    v-model="business_account.account_type"
                    :items="business_account_types_list"
                    label="Account Type"
                    :rules="[v => !!v || 'Account Type is required']"
                    dense
                    outlined
                  >
                  </v-select>
                  <v-tooltip bottom v-if="business_account_types_list_count == 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-top: -30px;"
                      color="grey lighten-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <span> Please add Business Account Type from Admin Section > Business Account Type</span>
                </v-tooltip>
                <v-text-field
                    v-model="business_account.url"
                    label="URL"
                    :rules="urlRules"
                    required
                    dense
                    outlined
                    class="pl-2"
                ></v-text-field>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="business_account.login_id"
                    label="Login Id"
                    required
                    dense
                    outlined
                    :rules="[v => !!v || 'Login Id is required']"
                    class="pr-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pl-2"
                    :append-icon="pass_show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="pass_show ? 'text' : 'password'"
                    @click:append="pass_show = !pass_show"
                    v-model="business_account.password"
                    :rules="[v => !!v || 'Password is required']"
                    label="Password"
                    required
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4">
                  <v-menu max-width="290" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="pr-2 date-text-input"
                        :value="computedDateFormattedMomentjs"
                        clearable
                        label="Next Renewal Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        @click:clear="business_account.renewal_date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="business_account.renewal_date"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="business_account.cost"
                    label="Cost/Subscription Amount"
                    required
                    dense
                    outlined
                    class="pl-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    class="pl-2"
                    v-model="business_account.subscription_duration"
                    :items="subscription_duration"
                    label="Subscription Duration"
                    :rules="[v => !!v || 'Subscription is required']"
                    dense
                    outlined
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" lg="12">
                  <v-textarea
                    v-model="business_account.note"
                    label="Note"
                    dense
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row fluid class="card-footer pl-3 pt-3">
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::BusinessAccount-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY_USERS } from "@/core/services/store/user.module";
import { GET_SUBSCRIPTION_DURATION } from "@/core/services/store/businessaccount.module";
import {
  STORE_BUSINESS_ACCOUNT,
  UPDATE_BUSINESS_ACCOUNT
} from "@/core/services/store/businessaccount.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import EventBus from "@/core/services/store/event-bus";

export default {
  name: "businessAccountCreate",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      business_account: {
        name: "",
        account_holder_id: "",
        login_id: "",
        password: "",
        account_type: "",
        url: "",
        renewal_date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        cost: "0.00",
        note: "",
        subscription_duration: "",
        selected_users: [],
      },
      pass_show: false,
      company_users: "",
      company_user_list: [],
      business_account_types_list: [],
      business_account_types_list_count: 0,
      valid: true,
      disable_btn: false,
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 25) || "Name must be less than 25 characters"
      ],
      urlRules: [
        v => !!v || "URL is required",
        v =>
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g.test(
            v
          ) || "URL must be valid"
      ],
      edit_form: false,
      subscription_duration: []
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"]),
    ...mapGetters(["getStateSubscriptionDuration"]),
    computedDateFormattedMomentjs() {
      return this.business_account.renewal_date
        ? moment(this.business_account.renewal_date).format("MM-DD-YYYY")
        : "";
    },
    icon() {
      if (this.likesAllUsers) return "mdi-close-box";
      if (this.likesSomeUser) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    config() {
      return this.layoutConfig();
    },
    likesAllUsers() {
      return (
        this.business_account.selected_users.length === this.company_user_list.length
      );
    },
    likesSomeUser() {
      return this.business_account.selected_users.length > 0 && !this.likesAllUsers;
    }
  },
  mounted() {
    let context = this;
    context.getBusinessAccountTypes();
    context.getCompanyUsers();
    context.getSubscriptionDuration();
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "business-account/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.business_account) {
            context.business_account = result.data.business_account;
            context.business_account.selected_users = result.data.business_account.selected_users;
          } else {
            context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "", "error");
          }
        },
        function(error) {
          context.disable_btn = false;
          Swal.fire("Error", "Business Account not found.", "error");
          context.$router.push({ name: "businessAccountList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Business Accounts" }]);

    const business_account_form = KTUtil.getById("kt_business_account_form");

    this.fv = formValidation(business_account_form, {});

    EventBus.$on("UPDATE_BUSINESS_ACCOUNT_ERROR", function(payLoad) {
      context.$router.push({ name: "businessAccountList" });
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    getSubscriptionDuration() {
      // let context = this;
      this.$store.dispatch(GET_SUBSCRIPTION_DURATION).then(() => {
        if (this.getSubscriptionDuration) {
          this.subscription_duration = this.getStateSubscriptionDuration;
        }
      });
    },
    getBusinessAccountTypes() {
      let context = this;
      axios({
        method: "get",
        url: "business-account-types",
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(function(result) {
        if (result.data.business_account_types) {
          result.data.business_account_types.forEach(ba_type => {
            context.business_account_types_list.push(ba_type.name);
            context.business_account_types_list_count += 1;
          });
        }
      });
    },
    getCompanyUsers() {
      let context = this;
      this.$store.dispatch(GET_COMPANY_USERS).then(() => {
        if (this.companyUsers) {
          this.company_users = this.companyUsers;

          this.company_users.forEach(user => {
            this.company_user_list.push(user.first_name + " " + user.last_name);
          });
        } else {
          axios({
            method: "get",
            url: "get-company-users",
            baseURL: process.env.VUE_APP_ACBACK_URL
          }).then(function(result) {
            if (result.data.company_users) {
              result.data.company_users.forEach(user => {
                context.company_user_list.push({
                  text: user.first_name + " " + user.last_name,
                  value: user.id
                });
              });
            }
          });
        }
      });
    },
    updateBusinessAccount() {
      var context = this;
      var formData = new FormData();

      formData.append("name", this.business_account.name);
      formData.append("login_id", this.business_account.login_id);
      formData.append("users", this.business_account.selected_users);
      formData.append("password", this.business_account.password);
      formData.append("account_type", this.business_account.account_type);
      formData.append("url", this.business_account.url);
      formData.append("renewal_date", this.business_account.renewal_date);
      formData.append("cost", this.business_account.cost);
      formData.append("note", this.business_account.note);
      formData.append("subscription_duration", this.business_account.subscription_duration);

      axios
        .post("business-account/" + this.$route.params.id, formData)
        .then(() => {
          Swal.fire("Updated", "Business account updated successfully", "success");
        })
        .then(() => {
          context.redirectToBusinessAccountList();
          context.disable_btn = false;
        },
        function(error) {
          Swal.fire("Error", "Business account not found.", "error");
        });  
    },
    redirectToBusinessAccountList() {
      let context = this;
      context.$router.push({ name: "businessAccountList" });
    },
    submitBusinessAccount() {

      var context = this;
      var formData = new FormData();

      formData.append("name", this.business_account.name);
      formData.append("login_id", this.business_account.login_id);
      formData.append("users", this.business_account.selected_users);
      formData.append("password", this.business_account.password);
      formData.append("account_type", this.business_account.account_type);
      formData.append("url", this.business_account.url);
      formData.append("renewal_date", this.business_account.renewal_date);
      formData.append("cost", this.business_account.cost);
      formData.append("note", this.business_account.note);
      formData.append("subscription_duration", this.business_account.subscription_duration);

      axios.post("business-account", formData).then(
        function(result) {
          context.$router.push({ name: "businessAccountList" });
          Swal.fire("Success", result.data.message, "success");
          context.disable_btn = false;
        },
        function() {
          Swal.fire("Error", "Error in save business account", "error");
          context.disable_btn = false;
        }
      );
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateBusinessAccount();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitBusinessAccount();
      } else {
        this.snackbar = true;
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllUsers) {
          this.business_account.selected_users = [];
        } else {
          this.business_account.selected_users = this.company_user_list.slice();
        }
      });
    }
  }
};
</script>
